.docs-layout {
    display: flex;
    height: calc(100vh - 43px);
    width: 100%;
}

.docs-container {
    flex: 1 1;
    height: 100%;
    width: 0px;
}

.docs-formatted-wrapper {
    padding: 40px;
}

.docs-nav {
    height: 100%;
}

.docs-nav-open {
    width: 250px;
    height: 100%;
}

.docs-nav-content {
    position: fixed;
    top: 43px;
    left: 0px;
    height: calc(100vh - 43px);
    background-color: #f1f1f1;
    background-color: white;
    border-right: 1px solid #BEBEBE;
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
}

.docs-nav-closed {
    width: 60px;
    height: 100%;
}

.docs-nav-open-content {
    width: 210px;
    padding: 20px;
}

.docs-nav-closed-content {
    width: 50px;
    padding: 5px;
}

.docs-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #BEBEBE;
    margin-bottom: 25px;
}

.docs-nav-header a {
    color: black;
}

.docs-nav-header-title {
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 1.2rem;
}

.docs-nav-header-icon {
    height: 1.2rem;
    cursor: pointer;
}

.docs-nav-item {
    list-style: none;
    margin-top: 5px;
}

.docs-nav-item a {
    color: black;
}

.docs-nav-open::-webkit-scrollbar {
    display: none;
}

.docs-nav-list-0 {
    padding-left: 0px;
}

.docs-nav-list-1 {
    padding-left: 10px;
}

.docs-nav-list-2 {
    padding-left: 20px;
}

.docs-nav-list-3 {
    padding-left: 20px;
}

.docs-nav-selected {
    font-weight: bold;
}

.docs-container-with-example {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.docs-content-with-example {
    flex: 1;
    min-width: 400px;
    padding-right: 40px;
}

.docs-example {
    flex: 1;
    min-width: 500px;
}


.docs-nav-list-1{
    margin-bottom: 10px;
}

.docs-container-no-example {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}