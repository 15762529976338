.toggle-div {
    border-radius: 2px;
    background-color: white;
    border: 1px solid #BEBEBE;
}

.toggle-div-header {
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid #BEBEBE;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
 
.toggle-div-content-wrapper {
    padding: 10px;
}