.file-uploader-container {
    width: 270px;
    margin: 10px;
}

.sheet-name-input {
    height: 1rem;
}

.custom-file-input {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 8rem;
    color: white;
    font-size: 1rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}

.custom-file-input:hover {
    cursor: pointer;
}

.file-uploader-submit-button-container {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.file-uploader-submit-button {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 8rem;
    color: white;
    font-size: 1rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}

.create-blank-sheet-button {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 8rem;
    color: white;
    font-size: 1rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}

.sheet-name-input {
    width: calc(100% - 1rem);
    height: 1.8rem;
    font-size: 0.9rem;
    border: 1px solid #BEBEBE;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: black;
    font-weight: 300;
    margin-bottom: 10px;
}

.sheet-name-input::placeholder, .sheet-name-input:-ms-input-placeholder, .sheet-name-input::-webkit-input-placeholder {
    color: #BEBEBE;
    font-weight: 300;
}

.sheet-name-input:focus {
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
    outline: none;

}