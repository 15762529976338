.spread-table-wrapper {
    width: 100%;
    border-radius: 2px;
    background-color: white;
    border: 1px solid #BEBEBE;
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
    box-sizing: border-box;
}

.spread-table {
    width: 100%;
    border-collapse: collapse;
}

.spread-table-title {
    font-size: 1.5rem;
}

.spread-table-header-row {
    background-color: #fafafa;
}

.spread-table-header-row td {
    border-right: 1px solid #BEBEBE;
    padding: 10px;
}

.spread-table-header-row td:last-child {
    border-right: none;
}

.spread-table-row {
    border-bottom: 1px solid #BEBEBE;
}

.spread-table-row-item {
    padding: 10px;
}

.spread-table-col-header {
    display: flex;
    justify-content: space-between;
}

.spread-table-select-item {
    width: 40px;
}

.spread-table-row-selected {
    background-color: #e5e5ff;
}

.spread-table-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #BEBEBE;
    background-color: #fafafa;
}

.spread-table-actions {
    display: flex;
    flex-wrap: wrap;
}

.spread-table-action {
    margin-left: 10px;
    margin-bottom: 5px;
}

.spread-button-no-min-width {
    min-width: 0px !important;
}

.spread-table-loading {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spread-table-empty {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: #BEBEBE;
}

.sort-icon {
    height: 1rem;
}