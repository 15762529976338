.spread-button {
    
    min-width: 8rem;
    
    font-size: 1rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}

.spread-button-dark {
    background-color: #1D2951;
    color: white;
}

.spread-button-light {
    background-color: white;
    color: #1D2951;
}

.spread-button-sharp {
    border-radius: 2px;
}

.spread-button-round {
    border-radius: 2rem;
}

.spread-button-disabled {
    background-color: #aaaaaa;
}

.spread-button-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(90% - 6px);
}

.spread-button-content-container {
    margin-left: 10px;
    margin-right: 10px;
}