/* .header {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #1D2951;
    width: 100%;
    color: white;
    height: 60px;
    display: flex;
    align-items: center; 
    z-index: 300;
} */

.header-logo-container {
    display: flex;
    align-items: center;
    padding-top: 5px;
}

.header-logo {
    height: 30px;
}

.header-body {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    width: 100vw;
    height: 100%;
}

.header-navbar {
    display: flex;
    width: 100%;
}

.header-nav {
    width: 100%;
}

.nav-list {
    list-style-type: none;
    height: 100%;
    margin: 0px;
    display: flex;
}

.nav-list li {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.nav-list li:first-child {
    margin-left: auto
}

.nav-item-label {
    text-align: center;
}

.header a {
    color: white;
    text-decoration: none;
}

.header a:hover {
    color: gray;
    text-decoration: none;
}


.header-container {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 300;
    height: 43px;
}

.header-cell {
    flex: 1;
    padding: 4px;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    box-sizing: border-box;
}

.header-cell:hover {
    background-color: #e0e0e0;
}

.header-cell:active {
    background-color: #c0c0c0;
}