.spreadml-login {
    width: 13rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.login-logo {
    width: 100%;
    padding-bottom: 30px;
}

.login-input {
    width: calc(100% - 1rem);
    height: 1.8rem;
    font-size: 0.9rem;
    border: 1px solid #BEBEBE;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: black;
    font-weight: 300;
}

.login-input::placeholder, .login-input:-ms-input-placeholder, .login-input::-webkit-input-placeholder {
    color: #BEBEBE;
    font-weight: 300;
}

.login-input:focus {
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
    outline: none;

}

.sign-up-disabled-message {
    font-size: 0.7rem;
    margin-bottom: 1rem;
    color: red;
    font-style: italic;
}

.name-input {
    margin-bottom: 1.2rem;
}

.username-input {
    margin-bottom: 1.2rem;
}

.password-input {
    margin-bottom: 0.5rem;
}

.password-input-signup {
    margin-bottom: 1.2rem;
}

.forgot-password-text-wrapper {
    font-size: 0.7rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.forgot-password-text {
    font-size: 0.7rem;
    font-weight: 600;
}

.forgot-password-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.login-button-divider {
    border: 0.5px solid #BEBEBE;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.login-button-container {
    display: flex;
    justify-content: center;
}

.login-button {
    border-radius: 2rem;
    width: calc(100% - 0.5rem);
    color: white;
    font-size: 1.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer
}

.login-button-top {
    background-color: #1D2951;
}

.login-button-bottom {
    background-color: #4E68C0;
}

.login-error-message {
    padding-top: 1rem;
    font-size: 0.7rem;
    color: red;
    text-align: center;
}

.verification-code-info {
    font-size: 0.7rem;
    margin-bottom: 10px;
}

.verification-code-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}