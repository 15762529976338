html {
    font-family: 'Inter', sans-serif;
    background-color: #e6ebf1;
    color: #373F51;
}

body {
    margin: 0px;
}

input::placeholder,
input:-ms-input-placeholder,
input::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
}

.spread-card {
    border-radius: 2px;
    padding: 10px;
    background-color: white;
    border: 1px solid #BEBEBE;
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
}

a {
    text-decoration: none;
}

.spread-link:hover {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

hr {
    border: 0.5px solid #BEBEBE;
}