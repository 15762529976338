.sheets-list-container {
    /* display: flex; */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
}

.sheets-list-container h2 {
    font-weight: 300;
}

.sheets-list-header {
    margin-top: 3rem;
}

.home-app-toggle-wrapper {
    display: flex;
    width: 100%;
    /* Span over the entire width */
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
}


.home-app-toggle-button {
    flex: 1;
    padding: 8px;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    /* Include borders in width calculation */
}

.home-app-toggle-selected {
    background-color: #e0e0e0;
    /* Additional styles for selected state, if desired */
}

/* Optional: Add styles for hover or active states */
.home-app-toggle-button:hover {
    background-color: #e0e0e0;
}

.home-app-toggle-button:active {
    background-color: #c0c0c0;
}

.create-sheet-button-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.create-sheet-button {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 8rem;
    color: white;
    font-size: 1.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}

.sheets-list {
    width: 100%;
    border: 1px solid #BEBEBE;
    border-radius: 2px;
    border-collapse: collapse;
}

.sheets-list-loader-container {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.sheets-list-row {
    width: 100%;
    border-bottom: 1px solid #BEBEBE;
    display: flex;
    height: 4.5rem;
}

.sheets-list-header-row {
    height: 3rem;
}

.sheets-list-col {
    display: flex;
    align-items: center;
}

.sheet-item {
    display: flex;
}

.sheet-link:hover {
    cursor: pointer;
}

.sheet-link {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 8rem;
    color: white;
    font-size: 1rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}

.spreadsheet-icon {
    height: 2.5rem;
    width: 2.5rem;
    padding-right: 2rem;
}

.dots-icon {
    height: 2rem;
    width: 2rem;
}

.dots-icon:hover {
    background-color: #dedede;
    border-radius: 0.2rem;
}

.sheet-name-col {
    flex: 12;
    padding-left: 1.5rem;
    font-weight: 700;
}

.date-created-col {
    flex: 12;
    justify-content: center;
    font-weight: 700;
}

.sheet-link-col {
    justify-content: right;
}

.options-col {
    flex: 1;
    justify-content: center;
    padding-right: 0.5rem;
}

.last-sheet-item {
    border-bottom: 0px;
}

.options-button {
    position: relative;
}

.options-dropdown {
    position: absolute;
    top: 90%;
    left: 0;
    background-color: white;
    border: 1px solid black;
    margin: 0px;
    z-index: 1;
    padding: 0px;
}

.options-dropdown li {
    list-style: none;
    padding: 5px;
}

.delete-sheet-option {
    cursor: pointer;
}

.delete-sheet-option:hover {
    background-color: lightgray;
}