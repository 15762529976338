.spread-app-container {
    height: 100%;
    background-color: white;
}

.spreadsheet-header-container {
    margin: 0px;
    font-size: 1rem;
    z-index: 2;
    height: 5.5rem;
    background-color: #1D2951;
    color: white;
    font-weight: 600;
}

.spreadsheet-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    height: calc(100% - 2rem - 1px);
    border-bottom: 1px solid #BEBEBE;
}

.spreadsheet-title {
    margin: 0px;
    font-size: 1rem;
    z-index: 2;
    height: 2.5rem;
}

.spreadsheet-options {
    display: flex;
    height: 1rem;
}

.spreadsheet-options-button {
    margin-right: 1rem;
    cursor: pointer;
    user-select: none;
}

.predictors-container {
    width: 18rem;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    box-shadow: -4px 0px 4px 0px hsla(0, 0%, 0%, 0.251);
}

.predictors-wrapper {
    padding: 1rem;
}


.spreadsheet-container {
    width: calc(100% - 18rem);
    height: 100%;
    flex: 3;
}

.spreadsheet-wrapper {
    display: flex;
    width: 100%;
    height: calc(100% - 5.5rem);
}

.spreadsheet-loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spread-app-models-container {
    width: 100%;
    margin-bottom: 20px;
}

.models-list-create-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.models-list-create-button {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 9rem;
    color: white;
    font-size: 1rem;
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}