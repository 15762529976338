.model-name-input {
    width: calc(100% - 1rem);
    height: 1.8rem;
    font-size: 0.9rem;
    border: 1px solid #BEBEBE;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: black;
    font-weight: 300;
}

.model-name-input::placeholder,
.login-input:-ms-input-placeholder,
.login-input::-webkit-input-placeholder {
    color: #BEBEBE;
    font-weight: 300;
}

.model-name-input:focus {
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.251);
    outline: none;

}

.trainer-header-back {
    cursor: pointer;
}

.input-col-boxes {
    max-height: 9rem;
    overflow-y: auto;
}

.columns-title {
    margin-bottom: 0.5rem;
}

.model-type-select-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.model-type-select {
    width: 100%;
    height: 1.8rem;
    font-size: 0.9rem;
    border: 1px solid #BEBEBE;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: black;
    font-weight: 300;
}

.model-train-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-train-button {
    background-color: #1D2951;
    border-radius: 2rem;
    width: 9rem;
    color: white;
    font-size: 1rem;
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}

.model-train-button-not-trainable {
    background-color: gray;
}

.status-fail {
    color: red;
}

.status-success {
    color: green;
}