.gpt-predictor-example-cell {
    background-color: white;
    width: fit-content;
    padding: 5px;
    border: 1px solid black;
    font-size: 1rem;
}

.gpt-predictor-example-cell-content {
    position: relative;
    display: inline-block;
    padding-right: 1px;
}

.gpt-predictor-example-cell-content::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    width: 2px;
    height: 1.1em;
    background-color: #000;
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    50% {
        visibility: hidden;
    }
}