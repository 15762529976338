.spinner {
    border: 2px solid;
    border-color: #d0d0d0 transparent #d0d0d0 transparent;
    border-radius: 50%;
    animation: spin-anim 1s linear infinite;
}

.spinner-fixed {
    width: 26px;
    height: 26px;
}

.spinner-dynamic {
    aspect-ratio: 1;
    height: 100%;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}