.spreadsheet {
    overflow-x: auto;
    overflow-y: auto;
    height: 100%;
}

.spreadsheet-table {
    background-color: white;
    border-collapse: collapse;
    width: 100%;
    display: flex;
}

.spreadsheet-row {
    display: flex;
}

.spreadsheet-header-row {
    display: flex;
    height: fit-content;
}

.spreadsheet-cell-col-idx {
    min-width: 0px;
    width: auto;
    padding-right: 4px;
    padding-left: 4px;
    text-align: center;
    color: #AC9E9E;
    user-select: none;
}

.spreadsheet-row-indexes {
    display: flex;
    flex-direction: column;
    height: min-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
}

.spreadsheet-row-index-container {
    color: #AC9E9E;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #BEBEBE;
    border-bottom: 1px solid #BEBEBE;
    box-sizing: border-box;
}

.spreadsheet-row-index {
    padding-right: 4px;
    padding-left: 4px;
    user-select: none;
}

.cell-resizeable {
    position: relative;
    overflow: visible;
}

.border-resize {
    position: absolute;
    z-index: 400;
}

.resize-row {
    width: 100%;
    height: 4px;
    left: 0;
    bottom: -3px;
}

.resize-col {
    height: 100%;
    width: 4px;
    right: -3px;
    top: 0;
    bottom: 0;
}