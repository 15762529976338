.modelslist-container {
    width: 100%;
}

.modelslist-container h4 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.models-list-container {
    padding-top: 10px;
}

.models-list-refresh-button {
    cursor: pointer;
    width: fit-content;
    background-color: #1D2951;
    border-radius: 2px;
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
    padding: 0.2rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.models-list-loader-container {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-item {
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.7rem;
    margin-bottom: 1rem;
    border: 1px solid #C1BDDB;
    cursor: pointer;
}

.model-item p {
    margin: 0px;
}

.model-item:hover {
    background-color: #C1BDDB;
}

.models-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.models-list-header-title {
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 1.2rem;
}

.models-list-header-icon {
    height: 1.2rem;
    cursor: pointer;
}

.models-list-header-bar {
    border-color: #373F51;
}

.model-status-in_progress {
    background-color: #eee;
    border: #eee;
}

.model-status-failed {
    background-color: rgb(220, 126, 126);
    border: rgb(220, 126, 126);
}

.model-status-failed:hover {
    background-color: rgb(185, 106, 106);
    border: rgb(220, 126, 126);
}