#app-container {
    padding-top: 43px;
}

.background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -100;
}

#p1 {
    background-image: linear-gradient(to right top, #ff99c9, #eba4de, #d3afec, #bdb8f1, #acc0ef, #a0c7f3, #96cef4, #8ed4f3, #7bdff9, #68e9fa, #5af3f6, #58fcec);

}

#p2 {
    height: 100vh;
    background-image: linear-gradient(to right bottom, #ff99c9, #eba4de, #d3afec, #bdb8f1, #acc0ef, #a0c7f3, #96cef4, #8ed4f3, #7bdff9, #68e9fa, #5af3f6, #58fcec);
}

.header {
    display: flex;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    font-weight: bold;
}

.cell {
    flex: 1;
    padding: 5px;
    text-align: center;
    border-right: 1px solid #ddd;
}

.cell:last-child {
    border-right: none;
}
