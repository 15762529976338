.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    
}

.modal-wrapper-parent-scope {
    position: absolute;
}

.modal-content {
    margin: 20px;
    background-color:white;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-height: 90%;
    overflow: auto;
}

.modal-content-dashboard {
    background-color: white;
}

.modal-close-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.modal-close-button {
    color: #BEBEBE;
    font-size: 1rem;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    cursor: pointer;
}

.modal-close-button:hover {
    background-color: #e3e3e3;
    border-radius: 2px;
}