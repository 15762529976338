.spreadsheet-cell {
    border-right: 1px solid #BEBEBE;
    border-bottom: 1px solid #BEBEBE;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px;
    font-size: 1rem;
    color: black;
    box-sizing: border-box;
    position: relative;
}

.spreadsheet-column-titles {
    height: 50px;
}

.spreadsheet-rowIdx-cell {
    min-width: 0px;
    width: auto;
    padding-right: 4px;
    padding-left: 4px;
    text-align: center;
    color: #AC9E9E;
}

.spreadsheet-cell-index-data {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.spreadsheet-cell-title-input-wrapper {
    width: 100%;
    height: 25px;
}

.spreadsheet-cell-input-wrapper {
    width: 100%;
    height: 100%;
}

.spreadsheet-cell-input {
    width: calc(100% - 4px);
    height: 100%;
    border: 0px;
    padding: 0px;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    padding-left: 4px;
}

.spreadsheet-cell-input:disabled {
    background-color: transparent;
    color: black;
    border: none;
    outline: none;
}

.spreadsheet-input-cell {
    background-color: #4b67c2a9;
    border-color: black;
}

.spreadsheet-output-cell {
    background-color: #C1BDDB;
    border-color: black;
}

.spreadsheet-cell-type-selector::-ms-expand {
    display: none;
}

.spreadsheet-cell-type-selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #C1BDDB;
    border-radius: 1rem;
    padding: 0.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.1rem;
    cursor: pointer;
}

.spreadsheet-cell-type-container option {
    display: flex;
    width: fit-content !important;
}

.spreadsheet-cell-loader-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spreadsheet-cell-loader-wrapper {
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cell-selected {
    border: 2px solid blue;
}

.cell-highlighted {
    background-color: #e5e5ff;
}